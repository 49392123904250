import React from 'react';
import icLogo from "../../components/assets/img/pages/produtos/ic-logo.png";

const ProductGroup = (props) => {
    const { products, categories, setDetail, handleBudget } = props;
    let categoriesItems = categories || [];
    return (
        <>
            {categoriesItems.map((categoria) => (
                <div key={categoria.ID.toString()}>
                    <div className="def__label__box" style={{ marginBottom: '.5rem' }}>
                        <div className="rectangle"></div>
                        <p className="label">{categoria.Title}</p>
                    </div>
                    <div className="product-group">
                        {
                            products.filter(item => item.CategoryID === categoria.ID && !item.hideBySearch).map((el, i) => {
                            // products.map((el, i) => {
                                return (
                                    <div key={i}>
                                        <div className="product-card" onClick={() => setDetail(el)} onKeyDown={() => setDetail(el)} role="button" tabIndex={-1}>
                                            <div className="product-img" style={{ background: `url(${el.Photo === '' ? icLogo : el.Photo}) no-repeat center/contain` }}></div>
                                            <h3 className="product-name">{el.Title}</h3>
                                            <h4 className="product-description">
                                                {el.InternalCode ? <>{el.InternalCode}<span>|</span></> : ''}
                                                
                                                {el.ModelCode}
                                            </h4>
                                            {el.isUsed ?
                                                <button className="product-button" disabled>Adicionado.</button>
                                                :
                                                <button className="product-button" onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleBudget(el, 'add');
                                                }}>Adicionar ao orçamento</button>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            ))}
        </>
    )
}

export default ProductGroup;